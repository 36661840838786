<!-- 胆码组弹窗组件-->
<template>
    <div id="dialog">
        <div class="border">
            <div @click="closeDialog" class="closeImg"><svg t="1634463638558" class="icon" viewBox="0 0 1024 1024"
                    version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1908" width="100%" height="100%">
                    <path
                        d="M823.04 823.04A439.893333 439.893333 0 1 1 341.333333 106.666667a439.893333 439.893333 0 0 1 481.706667 716.373333zM512 0a512 512 0 1 0 512 512A512 512 0 0 0 512 0z m205.226667 306.346667a35.84 35.84 0 0 0-50.773334 0L512 460.8 357.546667 306.346667a35.84 35.84 0 0 0-51.2 50.773333L461.226667 512l-154.453334 154.88a35.84 35.84 0 1 0 51.2 50.773333L512 563.2l154.453333 154.88a35.84 35.84 0 1 0 51.2-50.773333L562.773333 512l154.453334-154.88a35.84 35.84 0 0 0 0-50.773333z"
                        fill="#5E5C5C" p-id="1909"></path>
                </svg></div>
            <div style="font-size:16px;margin-top:5px;">胆码组：</div>
            <div class="wind">
                <!-- 号码选择区 -->
                <div class="win-ball">
                    <div class="number">
                        <div class="ball" :class="{ active: arr.indexOf(i) !== -1 }" v-for="(item, i) in 80" :key="i"
                            @click="ball(i)">
                            {{ 10 > item ? "0" + item : item }}
                        </div>
                        <div class="clear"></div>
                    </div>
                    <!-- 尾数选择区 -->
                    <div>
                        <button class="button" @click="ball3(i)" v-for="(item ,i) in button" :key="i">{{item}}</button>
                    </div>
                </div>
                <!-- 出号个数-->
                <div class="win-geshu">
                    <div class="win-geshu1">
                        <a>出现个数：</a>
                        <button class="button2" @click="ball2(i)" :class="{ active1: arr1.indexOf(i) !== -1 }"
                            v-for="(item ,i) in button2" :key="i">{{item}}</button>
                    </div>
                    <div class="win-geshu2">
                        <button class="button3" @click="add()" name='添加'>添加</button>
                    </div>
                </div>
                <!-- 条件显示区 -->
                <div class="win-tiaojian">
                    <div v-for="(item ,i) in  condition" :key="i">
                        <div class="win-t1">胆码组{{item.dmz}}</div>
                        <div class="win-t2">{{item.dm}}出{{item.gs}}个</div>
                        <div class=" wint3" :class="{ wint31: arr3.indexOf(i) !== -1 }" @click="revise(i,item)">修改</div>
                        <div class="win-t4" @click="del(i)">删除</div>
                    </div>
                </div>
                <div class="win-tianjia">
                    <div class="win-t5">
                        胆码组：由1到33个红球号码组成，选择一组用户认为要出现的号码，并设置要出现的个数，组合起来确定出号条件。例如设定胆码组号码为1,11,22，出现个数为2，则在过滤结果中的每注号码包含1,11,22中的其中两个。
                        胆码：用户认为本期开奖号码中最有可能出现的号码。
                    </div>
                    <div class="win-t6">
                        <div class="win-t7" @click="confirm()">
                            确认修改
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import api from '@/js/mpublic.js'
    export default {
        name: "wind",
        data() {
            return {
                arr: [],//下标
                arr0: [],//号码
                arr1: [],//出号个数
                arr2: [],//条件序号下标更新内容标记
                arr3: [],//条件修改按钮
                button: ['0尾', '1尾', '2尾', '3尾', '4尾', '5尾', '6尾', '7尾', '8尾', '9尾', '奇数', '偶数', '大尾', '小尾', '质数', '合数', '147尾', '258尾', '0369尾', '全选', '清除'],
                button2: ['0个', '1个', '2个', '3个', '4个', '5个', '6个', '7个', '8个', '9个'],
                bal: [
                    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
                    21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38,
                    39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56,
                    57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74,
                    75, 76, 77, 78, 79, 80,
                ],
                condition: [],

            }
        },
        mounted() {
            this.rev()
        },
        computed: {
            // sd() {
            //     return this.$store.state.sd;
            // },

        },
        methods: {

            ball(i) { //号码点击选择函数
                if (this.arr.indexOf(i) == -1) {
                    this.arr.push(i);
                    this.arr0.push(i + 1)
                } else {
                    this.arr.splice(this.arr.indexOf(i), 1);
                    this.arr0.splice(this.arr0.indexOf(i + 1), 1);
                }
                console.log("下标" + this.arr)
                console.log("号码" + this.arr0)
            },

            ball2(i) { //出号个数函数
                if (this.arr1.indexOf(i) == -1) {
                    this.arr1.push(i);
                    //this.arr2.push(i + 1)
                } else {
                    this.arr1.splice(this.arr1.indexOf(i), 1);
                    // this.arr2.splice(this.arr2.indexOf(i + 1), 1);
                }
                console.log(this.arr1)
            },

            add() { //条件添加按钮
                var aa = document.getElementsByClassName('button3')[0].innerHTML
                if (aa == '添加') {
                    var dmz = this.condition.length  //胆码组
                    var dm = api.smallb(this.arr0)//胆码
                    var gs = api.smallb(this.arr1)//出号个数
                    var zdgs = api.max(gs) //最大个数
                    var hmxb = this.arr//号码下标
                    if (dm.length !== 0 && gs.length !== 0) {
                        if (zdgs < dm.length || zdgs == dm.length) {
                            var obj = {
                                "dmz": dmz,
                                "dm": dm,
                                "gs": gs,
                                "hmxb": hmxb,
                            }
                            this.condition.push(obj)
                            this.arr = []
                            this.arr0 = []
                            this.arr1 = []
                        } else {
                            alert('选号个数 需要大于等于 出现个数')
                        }

                    } else {
                        alert("请选择号码及出现个数！")
                    }
                } else if (aa == '保存') {//保存按钮
                    var dm2 = api.smallb(this.arr0)
                    var gs2 = api.smallb(this.arr1)
                    var zdgs2 = api.max(gs2)
                    var hmxb2 = this.arr
                    if (dm2.length !== 0 && gs2.length !== 0) {
                        if (zdgs2 < dm2.length || zdgs2 == dm2.length) {
                            this.condition.splice(this.arr2, 1, { "dmz": this.arr2[0], "dm": dm2, "gs": gs2, "hmxb": hmxb2, })
                            this.arr = []
                            this.arr0 = []
                            this.arr1 = []
                            this.arr3 = []
                            document.getElementsByClassName('wint3')[this.arr2].style.backgroundColor = ""
                            document.getElementsByClassName('button3')[0].innerHTML = '添加'
                        } else {
                            alert('选号个数 需要大于等于 出现个数')
                        }
                    } else {
                        alert("请选择号码及出现个数！")
                    }
                }
                //console.log(aa)
            },

            del(i) {//删除条件数据
                this.condition.splice(i, 1);
                this.arr = []//下标
                this.arr0 = []//号码
                this.arr1 = []//出号个数
                this.arr3 = []
                document.getElementsByClassName('button3')[0].innerHTML = '添加'
                for (var j = 0; j < this.condition.length; j++) {//删除条件后对新的条件进行序号更新
                    if (this.condition[j].dmz !== j)
                        this.condition[j].dmz = j
                }
            },

            revise(i, item) {//条件修改
                this.arr2 = []
                this.arr2.push(i)
                document.getElementsByClassName('button3')[0].innerHTML = '保存'
                if (this.arr !== [] || this.arr0 !== [] || this.arr1 !== []) {
                    this.arr = []
                    this.arr0 = []
                    this.arr1 = []
                    for (var j = 0; j < item.dm.length; j++) {
                        this.ball(item.hmxb[j])
                    }
                    for (var v = 0; v < item.gs.length; v++) {
                        this.ball2(item.gs[v])
                    }
                } else {
                    for (var j1 = 0; j1 < item.dm.length; j1++) {
                        this.ball(item.hmxb[j1])
                    }
                    for (var v1 = 0; v1 < item.gs.length; v1++) {
                        this.ball2(item.gs[v1])
                    }
                }
                if (this.arr3.indexOf(i) == -1) {
                    if (this.arr3 !== []) {
                        this.arr3 = []
                        this.arr3.push(i);
                    } else {
                        this.arr3.push(i);
                    }

                } else {
                    this.arr3.splice(this.arr3.indexOf(i), 1);
                    this.arr = []
                    this.arr0 = []
                    this.arr1 = []
                    document.getElementsByClassName('button3')[0].innerHTML = '添加'
                }
            },

            confirm() {//确认修改
                this.$emit('closeDialog', false)
                if (this.condition != '') {
                    this.$emit('func', this.condition)//给父组件传值，并传递数据
                }


            },

            rev() {//页面启动加载
                for (var i = 0; i < this.inputname.length; i++) {
                    var obj = {
                        "dmz": this.inputname[i].dmz,
                        "dm": this.inputname[i].dm,
                        "gs": this.inputname[i].gs,
                        "hmxb": this.inputname[i].hmxb
                    }
                    this.condition.push(obj)
                    if (this.dd == i) {
                        this.revise(i, obj)
                        console.log("this.dd" + this.dd)
                    }
                }
            },

            closeDialog() {  //给父组件传参
                this.$emit('closeDialog', false)
            },
            //尾数选择函数1
            ball3(i) {
                if (i == 0) {
                    this.tail(this.ball3_1(9))
                } else if (i == 1) {
                    this.tail(this.ball3_1(0))
                } else if (i == 2) {
                    this.tail(this.ball3_1(1))
                } else if (i == 3) {
                    this.tail(this.ball3_1(2))
                } else if (i == 4) {
                    this.tail(this.ball3_1(3))
                } else if (i == 5) {
                    this.tail(this.ball3_1(4))
                } else if (i == 6) {
                    this.tail(this.ball3_1(5))
                } else if (i == 7) {
                    this.tail(this.ball3_1(6))
                } else if (i == 8) {
                    this.tail(this.ball3_1(7))
                } else if (i == 9) {
                    this.tail(this.ball3_1(8))
                } else if (i == 10) {
                    var aa = []
                    for (var v1 = 0; v1 < 80; v1++) {
                        if (v1 % 2 == 0) {
                            aa.push(v1)
                        }
                    }
                    this.tail(aa)
                } else if (i == 11) {
                    var aa1 = []
                    for (var v2 = 0; v2 < 80; v2++) {
                        if (v2 % 2 == 1) {
                            aa1.push(v2)
                        }
                    }
                    this.tail(aa1)
                } else if (i == 12) {
                    var aa2 = []
                    for (var v3 = 0; v3 < 80; v3++) {//大尾
                        if (v3 % 10 > 3 && v3 % 10 < 9) {
                            aa2.push(v3)
                        }
                    }
                    this.tail(aa2)
                } else if (i == 13) {
                    var aa3 = []//小尾
                    for (var v4 = 0; v4 < 80; v4++) {
                        if (v4 % 10 < 4 || v4 % 10 == 9) {
                            aa3.push(v4)
                        }
                    }
                    this.tail(aa3)
                } else if (i == 14) {
                    var aa4_1 = api.isPrime(80) //质数
                    this.tail(this.ball3_2(aa4_1))
                } else if (i == 15) {
                    var aa5 = []//合数
                    var aa5_1 = api.isPrime(80)
                    for (var v6 = 1; v6 <= 80; v6++) {
                        if (aa5_1.indexOf(v6) == -1) {
                            aa5.push(v6 - 1)
                        }
                    }
                    this.tail(aa5)
                } else if (i == 16) {//147尾
                    var aa6_1 = api.ysq(80)//147
                    this.tail(this.ball3_2(aa6_1))
                } else if (i == 17) {//258
                    var aa7_1 = api.ewb(80)
                    this.tail(this.ball3_2(aa7_1))
                } else if (i == 18) {//0369
                    var aa8_1 = api.slj(80)
                    this.tail(this.ball3_2(aa8_1))
                } else if (i == 19) {

                    for (var j = 0; j < 80; j++) {
                        if (this.arr.indexOf(j) == -1) {
                            this.arr.push(j);
                            this.arr0.push(j + 1)
                        }
                    }


                } else if (i == 20) {
                    for (var j1 = 1; j1 <= 80; j1++) {
                        this.arr.splice(this.arr.indexOf(j1), 1);
                        this.arr0.splice(this.arr0.indexOf(j1 + 1), 1);
                    }
                }

            },
            //尾数选择函数2
            ball3_1(i) {
                var bal = []
                for (var v = 0; v < 80; v++) {
                    if (v % 10 == i) {
                        bal.push(v)
                    }
                }
                return bal
            },
            //尾数选择函数3
            ball3_2(aa4_1) {
                var aa4 = []
                for (var v5 = 1; v5 <= 80; v5++) {
                    if (aa4_1.indexOf(v5) != -1) {
                        aa4.push(v5 - 1)
                    }
                }
                return aa4
            },
            //尾数选择函数4
            tail(array) {
                var tail_1 = api.same(array, this.arr)
                if (tail_1.length != array.length) {
                    for (var j = 0; j < array.length; j++) {
                        if (this.arr.indexOf(array[j]) == -1) {
                            this.arr.push(array[j]);
                            this.arr0.push(array[j] + 1)
                        }
                    }
                } else {
                    for (var j1 = 0; j1 < array.length; j1++) {
                        this.arr.splice(this.arr.indexOf(array[j1]), 1);
                        this.arr0.splice(this.arr0.indexOf(array[j1] + 1), 1);

                    }
                }

            },

        },
        props: ['inputname', 'dd'],
    }
</script>

<style scoped>
    #dialog {
        position: fixed;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.3);
        width: 100%;
        height: 100%;
    }

    .closeImg {
        width: 30px;
        height: 30px;
        float: right;
        margin-right: 5px;
        margin-top: 5px;
        cursor: pointer;
        /* background-image: url(../../assets/close.png); */
        /* background-color:rgb(194, 192, 192); */
        background-size: cover;
        background-repeat: no-repeat;
    }

    .border {
        text-align: center;
        background-color: white;
        border-radius: 20px;
        width: 500px;
        height: 500px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }



    .wind {
        float: left;
        width: 100%;

    }

    .win-ball {
        width: 100%;
    }

    .win-geshu {
        width: 100%;
        height: 10%;
    }

    .win-geshu1 {
        width: 80%;
        float: left;
        margin-top: 7px;
    }

    .button2 {
        margin-left: 5px;
    }

    .win-geshu2 {
        width: 18%;
        height: 80%;
        float: right;
        margin-top: 5px;
    }

    .button3 {
        width: 90%;
        height: 100%;
        border: 1px solid rgb(160, 158, 158);
        border-radius: 5px;
        color: aliceblue;
        background-color: rgba(252, 153, 24, 0.993);
    }

    .win-tiaojian {
        width: 99%;
        height: 120px;
        overflow: auto;
        border: 1px solid rgb(226, 224, 224);
    }

    .win-tianjia {
        width: 100%;
    }

    .number {
        width: 100%;
        margin: 0 auto;
        border-radius: 10px;
        border: 1px solid rgb(214, 213, 213);
    }

    .ball {
        float: left;
        margin-left: 6px;
        margin-top: 6px;
        line-height: 20px;
        width: 23px;
        height: 23px;
        font-size: 12px;
        border-radius: 12px;

        color: rgb(124, 122, 122);
        font-weight: bold;
        /* border: 1px solid red; */
        background-color: rgb(212, 211, 211);
    }

    .active {
        color: rgb(255, 251, 251);
        font-weight: bold;
        /* border: 1px solid red; */
        background-color: rgb(219, 133, 3);

    }


    .button {
        border: 1px solid rgb(167, 166, 166);
    }

    .active1 {
        background-color: red;
        color: aliceblue;
    }

    .win-t1 {
        width: 15%;
        align-items: center;
        display: inline-block;
    }

    .win-t2 {
        width: 60%;
        word-break: break-all;
        display: inline-block;
    }

    .wint3 {
        width: 10%;
        display: inline-block;
    }

    .wint31 {
        width: 10%;
        display: inline-block;
        background-color: red;
        color: aliceblue;
        border-radius: 5px;
    }

    .win-t4 {
        width: 10%;
        display: inline-block;
    }

    .win-t5 {
        width: 80%;
        display: inline-block;
        word-break: break-all;
        text-align: left;

    }

    .win-t6 {
        width: 18%;
        height: 100%;
        display: inline-block;
    }

    .win-t7 {
        width: 100%;
        height: 28px;
        background-color: red;
        color: aliceblue;
        display: inline-block;
        border-radius: 5px;
        line-height: 27px;

    }

    .clear {
        clear: both;
    }
</style>