<!-- 号码显示动态列表 -->
<template>
    <div class="list_pic">
        <!-- 标题 -->
        <div class="list-aa">
            <div class="list-aa1">
                序号
            </div>
            <div class="list-aa2">
                号码
            </div>
            <div class="clear"></div>
        </div>
        <!-- 列表 -->
        <div class="list_a ">
            <ul>
                <div class="rou_a" v-for="(item, i) in currentPageData" :key="i">
                    <li class="list-a1">
                        {{ currentPage*20-20+i+1}}
                    </li>
                    <li class="list-a2">
                        {{item.toString()}}
                    </li>
                    <div class="clear"></div>
                </div>

            </ul>
        </div>
        <!-- 分页按钮 -->
        <div class="list_b">
            <footer>
                <button style="margin-right:10px" @click="frontpage()">
                    首 页
                </button>
                <button @click="prevPage()">
                    上一页
                </button>
                <span>第{{currentPage}}页/共{{totalPage}}页</span>
                <button @click="nextPage()">
                    下一页
                </button>
                <button style="margin-left:10px" @click="Tail()">
                    尾 页
                </button>
            </footer>
        </div>

    </div>
</template>

<script>
    //import Pagination from '@/components/Pagination.vue'
    //import api from "@/js/mpublic.js";
    export default {
        name: 'klb-list',
        components: {
            // Pagination
        },
        data() { // 数据源
            return {
                arr0:[],
                totalPage: 1, // 统共页数，默认为1
                currentPage: 1, //当前页数 ，默认为1
                pageSize: 20, // 每页显示数量
                currentPageData: [], //当前页显示内容

            };
        },

        mounted() {
        },
        computed: {

        },
        methods: {
            parentHandleclick(fData) { 
                this. currentPage=1    
                this.arr0=fData
                // 计算一共有几页
                this.totalPage = Math.ceil(fData.length / this.pageSize);
                // 计算得0时设置为1
                this.totalPage = this.totalPage == 0 ? 1 : this.totalPage;
                let begin = (this.currentPage - 1) * this.pageSize;
                let end = this.currentPage * this.pageSize;
                this.currentPageData = fData.slice(
                    begin,
                    end
                );

              
               
            },

            // 设置当前页面数据，对数组操作的截取规则为[0~10],[10~20]...,
            setCurrentPageData() {
                let begin = (this.currentPage - 1) * this.pageSize;
                let end = this.currentPage * this.pageSize;
                this.currentPageData = this.arr0.slice(
                    begin,
                    end
                );
               
            },
            //首页
            frontpage(){
                this.currentPage=1;
                this.setCurrentPageData();
            },
            //上一页
            prevPage() {
                if (this.currentPage == 1) return;
                this.currentPage--;
                this.setCurrentPageData();
            },

            // 下一页
            nextPage() {
                if (this.currentPage == this.totalPage) return;
                this.currentPage++;
                this.setCurrentPageData();

            },

            //尾页
            Tail(){
                this.currentPage=this.totalPage;
                this.setCurrentPageData();
            }
        },


        props: [],





    }
</script>

<style scoped>
    /* * {
        border: 1px solid red;
    } */

    .list_pic {
        width: 100%;
        float: right;
    }

    .list_a {
        height: 290px;
        OVERFLOW: auto;
    }

    .list-aa {
        background-color: rgb(216, 219, 216);
    }

    .list-aa1 {
        width: 10%;
        float: left;
    }

    .list-aa2 {
        width: 80%;
        float: left;
    }

    .list-a1 {
        float: left;
        width: 12%;
        font-size: 15px;
    }

    .list-a2 {
        /* float: left;
        margin-top: 20px;
        list-style: none;
        color: #000;
        font-size: 1vw;
        display: block;
        width: 20%;
        margin-left: 4%;
        height: 200px; */
        text-align: left;
        font-size: 15px;
        width: 80%;
        float: left;
        letter-spacing:1px;
        border: 1px solid rgb(208, 215, 224);

    }
</style>